import React from 'react';

export default function Table({thead, tbody}) {
    return (
        <table>
            {thead}
            {tbody}
        </table>
    );
}
