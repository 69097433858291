export const TRANSLATIONS = {
  "itemTable": {
    "name": "Item name",
    "price": "Price",
    "value": "Value",
    "highAlchemyProfit": "Profit",
    "highAlchemyProfitDisplay": "Profit",
    "highAlchemyValue": "Alch value",
    "description": "Description",
    "changeOneDay": "Change 24h",
    "buyLimit": "Buy limit",
    "highAlchemyProfitPerBuyLimit": "Profit / buy limit",
    "highAlchemyProfitPerBuyLimitDisplay": "Profit / buy limit"
  },
  "merchLog": {
    "itemName": "Item name",
    "amount": "Amount",
    "buyPrice": "Buy price",
    "sellPrice": "Sell price",
    "profitTotal": "Total profit",
    "id": "Merch ID",
    "comment": "Comment",
    "actions": "Actions",
    "deleteMerch": "Delete merch",
    "submitLog": "Submit merch log",
    "merchCommentPlaceholder": "This merch was amazing..",
    "itemNamePlaceholder": "Blue partyhat"
  },
  "dashboard": {
    "name": "Item name",
    "totalProfit": "Total profit",
    "averageProfit": "Average profit",
    "averageProfitPerItem": "Average profit/item"
  },
  "highAlchemy": {
    "highAlchemyCost": "High alchemy price",
    "limitRows": "Limit # of rows displayed",
    "warningNatureRuneCannotBeLoaded": "Warning: Price for Nature rune could not be loaded",
  },
  "error": {
    "invalidItem": "Invalid item entered"
  }
};
