import React from 'react';
import SortableTable from "../../BaseReactComponents/Table/SortableTable";
import DeleteMerchButton from "./DeleteMerchButton";
import {useTranslations} from "../../../customHooks/useTranslations";

const fields = ['id', 'itemName', 'comment', 'amount', 'buyPrice', 'sellPrice', 'profitTotal', 'actions'];

export default function MerchLogTable({merches, setMerches}) {
    const [translations] = useTranslations();
    const mapMerches = (merches) => {
        return merches.map(merch => {
            return {
                ...merch,
                profitTotal: (merch.sellPrice - merch.buyPrice) * merch.amount,
                actions: <DeleteMerchButton merch={merch} setMerches={setMerches}/>,
            };
        });
    };

    return <SortableTable defaultSortKey='id' unsortableFields={['actions']} fieldTranslations={translations.merchLog} fields={fields} values={mapMerches(merches)}/>
}
