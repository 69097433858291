import React, {useState} from 'react';
import TableHeadField from "./TableHeadField";
import TableHead from "./TableHead";
import TableRow from "./TableRow";
import TableBody from "./TableBody";
import TableField from "./TableField";
import Table from "./Table";
import Sorter from "../../../helpers/Sorter/Sorter";

export default function SortableTable({defaultSortKey, fieldTranslations, fields, values, unsortableFields, limitRows}) {
    const [sortKey, _setSortKey] = useState(defaultSortKey);
    const [reverseSort, setReverseSort] = useState(false);

    const setSortKey = (value) => {
        if (sortKey === value) {
            setReverseSort(!reverseSort);
        } else {
            _setSortKey(value);
        }
    };

    const getValues = () => {
        if (Array.isArray(unsortableFields) && unsortableFields.includes(sortKey)) {
            return values;
        }
        const sorted = Sorter(values, sortKey, reverseSort);
        return limitRows !== undefined ? sorted.slice(0, limitRows) : sorted;
    };

    return <div>
        <Table
            thead={generateTableHead(setSortKey, fields, fieldTranslations)}
            tbody={generateTableBody(getValues(), fields)}
        />
    </div>
}

const generateTableHead = (setSortKey, fields, fieldTranslations) => {
    if (Array.isArray(fields)) {
        const ths = fields.map(field => {
            return <TableHeadField key={field} onClick={() => setSortKey(field)}
                                   content={fieldTranslations !== undefined ? fieldTranslations[field] : field}/>
        });
        return <TableHead trs={<TableRow tds={ths}/>}/>
    }
    return null;
};

const generateTableBody = (values, fields) => {
    if (Array.isArray(values) && Array.isArray(fields)) {
        return <TableBody trs={generateTableRows(values, fields)}/>
    }
    return null;
};

const generateTableRows = (values, fields) => {
    return values.map((value, key) => {
        const tds = fields.map(field => {
            return <TableField key={field} content={value[field]}/>
        });
        return <TableRow key={key} tds={tds}/>
    });
};
