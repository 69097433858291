import React from 'react';
import './profit-output.scss';

export default function ProfitOutput ({value}) {
    const coinsClass = determineCoinClass(value);
    let className = 'neutral coins';
    if (value > 0) {
        className = 'positive coins';
    } else if (value < 0) {
        className = 'negative coins';
    }
    return <span className={className + ' ' + coinsClass}>{value}</span>
}

const determineCoinClass = (value) => {
    if (value < 0) {
        value *= -1;
    }
    if (value === 0) {
        return '';
    }

    if (value > 10000) {
        return "coins-10000";
    } else if (value > 1000) {
        return "coins-1000";
    } else if (value > 250) {
        return "coins-250";
    } else if (value > 100) {
        return "coins-100";
    } else if (value > 25) {
        return "coins-25";
    } else if (value > 4) {
        return "coins-5";
    } else {
        return "coins-" + Math.floor(value);
    }
};
