import React, {useState, useEffect} from 'react';
import GetMerches from "../../services/GetMerches/GetMerches";
import MerchTable from "./MerchTable/MerchTable";
import MerchOverview from "./MerchOverview/MerchOverview";

export default function Dashboard() {
    const [merches, setMerches] = useState([]);

    useEffect(() => GetMerches({setMerches}), []);

    return <div>
        <MerchOverview merches={merches}/>
        <MerchTable merches={merches}/>
    </div>
}
