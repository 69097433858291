import React from 'react';
import SortableTable from "../../BaseReactComponents/Table/SortableTable";
import {
    getAverageProfit,
    getAverageProfitPerItem,
    getTotalProfit,
    groupByItem
} from "../../../helpers/MerchHelper/MerchHelper";
import {useTranslations} from "../../../customHooks/useTranslations";

const fields = ['name', 'totalProfit', 'averageProfit', 'averageProfitPerItem'];
export default function MerchTable({merches}) {
    const [translations] = useTranslations();
    return <SortableTable values={getTableData(merches)} fields={fields} fieldTranslations={translations.dashboard}/>
}

const getTableData = merches => {
    const groupedMerches = groupByItem(merches);
    return Object.keys(groupedMerches).map(itemName => {
        return {
            name: itemName,
            totalProfit: getTotalProfit(groupedMerches[itemName]),
            averageProfitPerItem: getAverageProfitPerItem(groupedMerches[itemName]),
            averageProfit: getAverageProfit(groupedMerches[itemName]),
        };
    });
};
