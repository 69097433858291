import React, {useState} from 'react';
import TextInput from "../BaseReactComponents/TextInput/TextInput";
import {useTranslations} from "../../customHooks/useTranslations";
import {useItems} from "../../customHooks/useItems";

export default function ItemSuggestionSearch({setItem}) {
    const [val, setVal] = useState('');
    const [translations] = useTranslations();
    const [items] = useItems();

    const handleTextChange = (e) => {
        setVal(e.target.value);
        const selectedOption = document.querySelector('#item-list option[value=\'' + e.target.value + '\']');
        if (selectedOption !== null) {
            setItem(JSON.parse(selectedOption.dataset.item));
        } else {
            setItem({});
        }
    };

    return <div>
        <TextInput
            required
            value={val}
            placeholder={translations.merchLog.itemNamePlaceholder}
            onChange={handleTextChange}
            list="item-list"
        />
        {val.length > 1 ? generateDataListFromItems(items, val) : null}
    </div>
}

const generateDataListFromItems = (items, val) => {
    const filteredItems = items.filter(item => item.name !== undefined && item.name.indexOf(val) !== -1);
    return <datalist id="item-list">
        {
            filteredItems.length === 1 ? null : filteredItems.map((item, key) => <option
                data-item={JSON.stringify(item)} key={key}
                value={item.name}>{item.description}</option>)
        }
    </datalist>
};
