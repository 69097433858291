export const getTotalAmount = merches =>  merches.reduce((total, current) => total + current.amount, 0);
export const getAverageProfitPerItem = merches => returnZeroIfIsNaN(Math.floor(getTotalProfit(merches) / getTotalAmount(merches)));
export const getTotalProfit = merches => merches.reduce((total, current) => total + getProfit(current), 0);
export const getProfit = merch => (merch.sellPrice - merch.buyPrice) * merch.amount;
export const getAverageProfit = merches => returnZeroIfIsNaN(Math.floor(getTotalProfit(merches) / merches.length));
export const groupByItem = merches => {
    const items = {};
    merches.forEach(merch => {
        if (items[merch.itemName] === undefined) {
            items[merch.itemName] = [];
        }
        items[merch.itemName].push(merch);
    });
    return items;
};

const returnZeroIfIsNaN = (result) => {
    return isNaN(result) ? 0 : result;
};
