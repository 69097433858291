import React, {useState} from "react";
import {generateSteps} from "./StepGenerator";

const steps = generateSteps();

export default function CountingUpDisplay({targetNumber, countInterval}) {
    const [displayNumber, setDisplayNumber] = useState(0);
    const [stepCounter, setStepCounter] = useState(0);
    if (countInterval === undefined) {
        countInterval = 25;
    }
    if (stepCounter < steps.length) {
        setTimeout(() => {
            setDisplayNumber(Math.floor(targetNumber * steps[stepCounter] / 100));
            setStepCounter(stepCounter + 1);
        }, countInterval);
    }
    return <div>{displayNumber}</div>
}
