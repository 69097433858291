export const addHighAlchemyProfitToItem = (item, highAlchemyPrice) => {
    return {
        ...item,
        highAlchemyValue: getHighAlchemyValue(item),
        highAlchemyProfit: getHighAlchemyProfit(item, highAlchemyPrice),
        highAlchemyProfitPerBuyLimit: getHighAlchemyProfit(item, highAlchemyPrice) * item.buyLimit
    };
};

export const getHighAlchemyValue = (item) => {
    if (item.storeValue === undefined || item.storeValue <= 0) {
        return 0;
    }
    return Math.floor(item.storeValue * 0.6);
};

export const getHighAlchemyProfit = (item, highAlchemyPrice) => getHighAlchemyValue(item) - item.price - highAlchemyPrice;
