export const STORAGE_KEY = 'rs3-merch.merches';

export default function AddMerch({amount, buyPrice, sellPrice, item, comment, setMerches}) {
    const storage = JSON.parse(localStorage.getItem(STORAGE_KEY)) || [];
    const id = parseInt(getHighestId(storage)) + 1;
    storage.push({
        amount,
        buyPrice,
        id: id,
        itemId: item.id,
        itemName: item.name,
        sellPrice,
        comment
    });
    localStorage.setItem(STORAGE_KEY, JSON.stringify(storage));
    setMerches(storage);
}

const getHighestId = (storage) => {
    if (storage.length === 0) {
        return 0;
    }
    let highestId = 1;
    storage.forEach(store => {
        if (store.id > highestId) {
            highestId = store.id;
        }
    });
    return highestId;
};
