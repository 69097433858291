import React from 'react';
import DisplayBox from '../../DisplayBox/DisplayBox';
import {
    getAverageProfit,
    getAverageProfitPerItem,
    getTotalAmount,
    getTotalProfit
} from "../../../helpers/MerchHelper/MerchHelper";
import CountingUpDisplay from "../../CountingUpDisplay/CountingUpDisplay";
import './merch-overview.scss';

export default function MerchOverview({merches}) {
    return <div id="merch-overview">
        <DisplayBox content={<CountingUpDisplay targetNumber={getTotalProfit(merches)}/>} title="Total profit"/>
        <DisplayBox content={<CountingUpDisplay targetNumber={getAverageProfit(merches)}/>} title="Average profit"/>
        <DisplayBox content={<CountingUpDisplay targetNumber={getAverageProfitPerItem(merches)}/>} title="Average profit per item"/>
        <DisplayBox content={<CountingUpDisplay targetNumber={getTotalAmount(merches)}/>} title="Total amount of items merched"/>
    </div>
}
