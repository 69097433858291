import {useState, useEffect} from 'react';
import GetAlchableItems from "../services/GetAlchableItems/GetAlchableItems";

const NATURE_RUNE_ID = 561;
export const useGetHighAlchemyItems = () => {
    const [items, setItems] = useState([]);
    useEffect(() => {
        GetAlchableItems({setItems});
    }, []);

    if (items.length > 0) {
        return [items, getItemById(items, NATURE_RUNE_ID)];
    }
    return [items, null];
};

const getItemById = (items, id) => {
    const filtered = items.filter(item => item.id === id);
    if (filtered.length === 1) {
        return filtered[0];
    }
    return undefined;
};
