import React from 'react';
import './error.scss';

export default function Error ({message}) {
    return message === undefined ? <div/> : <div className="error-box">
        <div className="symbol">!</div>
        <div className="message">
            <span>{message}</span>
        </div>
    </div>
}
