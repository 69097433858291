import React from 'react'
import TextInput from "../../BaseReactComponents/TextInput/TextInput";

export default function AliasInput({onChangeSetter, ...props}) {
    const handleChange = (e) => {
        let value = e.target.value;
        value = value.replace(/[-,.]/, '');
        const multiplierMap = {
            'k': '000',
            'm': '000000',
            'b': '000000000'
        };
        for (let multiKey in multiplierMap) {
            value = value.replace(multiKey, multiplierMap[multiKey]);
        }
        onChangeSetter(isNaN(parseInt(value)) ? 0 : parseInt(value));
    };

    return <TextInput {...props} onChange={handleChange}/>
}
