import Dashboard from "../Dashboard/Dashboard";
import HighAlchemy from "../HighAlchemy/HighAlchemy";
import MerchLog from "../MerchLog/MerchLog";

export default class RouteConfig {
    static config = [
        {
            'path': '/dashboard',
            'component': Dashboard,
            'name': 'Dashboard',
        },
        {
            'path': '/merch-log',
            'component': MerchLog,
            'name': 'Merch log',
            'default': true
        },
        {
            'path': '/high-alchemy',
            'component': HighAlchemy,
            'name': 'High alchemy',
        },
    ];

    static getAll() {
        return RouteConfig.config;
    }

    static getDefaultPath() {
        const defaultConfig = RouteConfig.config.filter(d => {return d.default});
        if (defaultConfig !== undefined && defaultConfig.length === 1) {
            return defaultConfig[0].path;
        }
        return '/';
    }
}
