import RouteConfig from "../Main/RouteConfig";
import React from "react";
import {NavLink} from "react-router-dom";
import * as icon from '../../assets/img/runescape_logo.png';

import './navigation.scss';

const generateNavigationLinks = () => {
    return <ul>
        {
            RouteConfig.getAll().map((route) => {
                return <li key={route.path}>
                    <NavLink to={route.path}>{route.name}</NavLink>
                </li>
            })
        }
    </ul>
};

export default function Navigation() {
    return <nav>
        <div className="icon vertical-center">
            <img src={icon} alt="Runescape3 Merch"/>
        </div>
        <div className="main-nav">
            {generateNavigationLinks()}
        </div>
    </nav>
}
