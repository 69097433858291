import React, {useState} from 'react';
import ItemTable from "../ItemTable/ItemTable";
import ProfitOutput from "../ProfitOutput/ProfitOutput";
import Label from "../BaseReactComponents/Label/Label";
import NumberInput from "../BaseReactComponents/NumberInput/NumberInput";
import {addHighAlchemyProfitToItem} from "../../helpers/AlchemyHelper/AlchemyHelper";
import './high-alchemy.scss';
import {useGetHighAlchemyItems} from "../../customHooks/useGetHighAlchemyItems";
import {useTranslations} from "../../customHooks/useTranslations";

const fields = [
    'name',
    'price',
    'highAlchemyValue',
    'highAlchemyProfitDisplay',
    'buyLimit',
    'highAlchemyProfitPerBuyLimitDisplay'
];

export default function HighAlchemy() {
    const [highAlchemyPrice, setHighAlchemyPrice] = useState(null);
    const [limitRows, setLimitRows] = useState(50);
    const [translations] = useTranslations();

    const [alchableItems, natureRune] = useGetHighAlchemyItems();
    if (natureRune === undefined) {
        return <div>{translations.highAlchemy.warningNatureRuneCannotBeLoaded}</div>
    }
    if (natureRune !== null) {
        if (highAlchemyPrice === null) {
            setHighAlchemyPrice(natureRune.price);
        }
        return <div id="high-alchemy-table">
            <div className="high-alchemy-cost">
                <Label text={translations.highAlchemy.highAlchemyCost}
                       formField={<NumberInput min="0" value={highAlchemyPrice}
                                               onChange={(e) => setHighAlchemyPrice(e.target.value)}/>}/>
            </div>
            <div className="limit-rows">
                <Label text={translations.highAlchemy.limitRows}
                       formField={<NumberInput min="1" value={limitRows}
                                               onChange={(e) => setLimitRows(e.target.value)}/>}/>
            </div>
            <div className="item-table">
                <ItemTable limitRows={limitRows} items={formatItems(alchableItems, highAlchemyPrice)} fields={fields}/>
            </div>
        </div>
    }
    return <div/>;
}

const formatItems = (items, highAlchemyPrice) => {
    return items
        .map(item => addHighAlchemyProfitToItem(item, highAlchemyPrice))
        .map((item, key) => {
            return {
                ...item,
                highAlchemyProfitDisplay: <ProfitOutput key={key} sortBy={item.highAlchemyProfit}
                                                        value={item.highAlchemyProfit}/>,
                highAlchemyProfitPerBuyLimitDisplay: <ProfitOutput key={key} sortBy={item.highAlchemyProfitPerBuyLimit}
                                                                   value={item.highAlchemyProfitPerBuyLimit}/>
            }
        });
};
