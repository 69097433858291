import React, {useState} from 'react';
import CustomForm from "../../BaseReactComponents/Form/Form";
import SubmitButton from "../../BaseReactComponents/SubmitButton/SubmitButton";
import Label from "../../BaseReactComponents/Label/Label";
import AddMerch from "../../../services/AddMerch/AddMerch";
import TextInput from "../../BaseReactComponents/TextInput/TextInput";
import AliasInput from "../AliasInput/AliasInput";
import './add-merch-log.scss';
import ItemSuggestionSearch from "../../ItemSuggestionSearch/ItemSuggestionSearch";
import Error from "../../Error/Error";
import {useTranslations} from "../../../customHooks/useTranslations";

export default function AddMerchLog({setMerches}) {
    const [amount, setAmount] = useState(1);
    const [buyPrice, setBuyPrice] = useState(1);
    const [sellPrice, setSellPrice] = useState(1);
    const [item, _setItem] = useState({});
    const [comment, setComment] = useState('');
    const [error, setError] = useState(undefined);
    const [translations] = useTranslations();

    const setItem = (item) => {
        if (item.buyLimit !== undefined) {
            setError(undefined);
            _setItem(item);
            setAmount(item.buyLimit);
            setBuyPrice(item.price);
            setSellPrice(item.price);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (item.id === undefined) {
            setError(translations.error.invalidItem);
        } else {
            setError(undefined);
            AddMerch({amount, sellPrice, buyPrice, item, comment, setMerches});
        }
    };

    const generateFormFields = () => {
        return <div>
            <div>
                <Error message={error}/>
            </div>
            <div id="add-merch-log">
                <div className="item-name">
                    <Label text={translations.merchLog.itemName}
                           formField={<ItemSuggestionSearch setItem={setItem}/>}/>
                </div>
                <div className="amount">
                    <Label text={translations.merchLog.amount}
                           formField={<AliasInput value={amount} onChangeSetter={setAmount}/>}/>
                </div>
                <div className="comment">
                    <Label text={translations.merchLog.comment}
                           formField={<TextInput value={comment}
                                                 placeholder={translations.merchLog.merchCommentPlaceholder}
                                                 onChange={e => setComment(e.target.value)}/>}/>
                </div>
                <div className="buy-price">
                    <Label text={translations.merchLog.buyPrice}
                           formField={<AliasInput value={buyPrice} onChangeSetter={setBuyPrice}/>}/>
                </div>
                <div className="sell-price">
                    <Label text={translations.merchLog.sellPrice}
                           formField={<AliasInput value={sellPrice} onChangeSetter={setSellPrice}/>}/>
                </div>
                <div className="submit">
                    <Label text="&nbsp;"
                           formField={<SubmitButton value={translations.merchLog.submitLog}/>}/>
                </div>
            </div>
        </div>
    };

    return <CustomForm onSubmit={handleSubmit} formFields={generateFormFields()}/>
}
