import RouteConfig from "./RouteConfig";
import {BrowserRouter, Redirect, Route} from "react-router-dom";
import React from "react";
import Navigation from "../Navigation/Navigation";
import Footer from "./Footer";
import './main.scss';

export default function Main() {
    const generateRoutes = () => {
        return RouteConfig.getAll().map((route) => {
            return <Route key={route.path} path={route.path} exact component={route.component}/>;
        });
    };

    return <BrowserRouter>
        <Redirect to={RouteConfig.getDefaultPath()}/>
        <div className="container">
            <Navigation/>
            <main>
                {generateRoutes()}
            </main>
            <Footer/>
        </div>
    </BrowserRouter>
}
