export const generateSteps = () => {
    const steps = [];
    for (let i = 1; i < 16; i++) {
        steps.push(i*5);
    }
    for (let i = 16*5; i < 101; i++) {
        steps.push(i);
    }
    return steps;
};
