import React from 'react';
import './merch-log.scss';
import MerchLogTable from "./MerchLogTable/MerchLogTable";
import AddMerchLog from "./AddMerchLog/AddMerchLog";
import {useMerches} from "../../customHooks/useMerches";

export default function MerchLog() {
    const [merches, setMerches] = useMerches();
    return <div id="merch-log">
        <div className="header">
            <AddMerchLog setMerches={setMerches}/>
        </div>
        <div className="main">
            <MerchLogTable merches={merches} setMerches={setMerches}/>
        </div>
    </div>
}
